import React from 'react'
import logo from "../assets/logo.jpg"
import styled from 'styled-components'
import EmblaCarousel from './slideshow';
import '../css/embla.css'
import logoTransparent from "../assets/logo-transparent.png"
const OPTIONS = { loop: true }
const SLIDE_COUNT = 7
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

const appLink = "https://link.storjshare.io/s/jxpeno6as2bgdscwsd2uwsm4lzua/aylive/AyliveUser_December14.apk"

function Hero() {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = appLink; // Ensure this path is correct
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleLearnMore = () => {
        const link = document.createElement('a');
        link.href = "/#LearnMore"; // Ensure this path is correct
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <Container>
            <EmblaCarousel slides={SLIDES} options={OPTIONS} />
            <Logo>
                <img src={logoTransparent} />
                <div>
                    <LearnMore onClick={handleLearnMore}>Learn More</LearnMore>
                    <Download onClick={handleDownload}>Download</Download>
                </div>
            </Logo>
        </Container>
    )
}

const Download = styled.button`
    border: none;
    border: 1px solid orange;
    background-color: rgba(255, 77, 32, 0.068);
    box-shadow: 0 0px 15px rgba(255, 77, 32, 0.4);
    &:hover{
        background-color: rgba(255, 77, 32, 0.268);
        box-shadow: 0 5px 15px rgba(255, 77, 32, 0.4);
    }
`;
const LearnMore = styled.button`
    background-color: rgba(20, 87, 255, 0.068);
    border: 1px solid lightblue;
    box-shadow: 0 0px 15px rgba(20, 87, 255, 0.4);
    &:hover{
        background-color: rgba(20, 87, 255, 0.268);
        box-shadow: 0 5px 15px rgba(20, 87, 255, 0.4);
    }
`;

const Logo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;

    img {
        width: 70%;
        aspect-ratio: 5685/2027;
    }

    &>div{
        display: flex;
        justify-content: center;
    }

    button {
        padding: 1em 2em;
        border-radius: 15px;
        margin: 0.5em;    
        font-size: 18px;
        color: white;
        /* background-color: black; */
        cursor: pointer;
    }
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100vh;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 100px;
    position: relative;
    flex-direction: column;

`;


export default Hero
