import React from 'react'
import styled from 'styled-components';

function LearnMore() {
    const appLink = "https://link.storjshare.io/s/jwmetbc55u7macbx2n7jdarfubrq/aylive/aylive-user.apk"
    return (
        <Container id='LearnMore'>
            <h1>Learn More</h1>
            <div className="infos">
                <InfoContainer>
                    <h3>AyLive</h3>
                    <p>Aylive is a livestream and waging platform straight to your mobile app. Winning odds are higher, easy and reliable transactions, and earn as much as you want!</p>
                </InfoContainer>
                <InfoContainer>
                    <h3>Getting Started</h3>
                    <ol>
                        <li>Download the <a href={appLink} download>application</a></li>
                        <li>Create an account within the app</li>
                        <li>Verify your email to continue</li>
                        <li>Top-up your wallet to bet (minimum of Php 100 per bet)</li>
                        <li>You can request for deposit or withdrawal</li>
                    </ol>
                </InfoContainer>
                <InfoContainer>
                    <h3>Deposit Process</h3>
                    <ol>
                        <li>In the menu, click wallet</li>
                        <li>Click on deposit money</li>
                        <li>Steps will be shown within the app</li>
                        <li>Fill all fields and submit request</li>
                        <li>Your request will be processed within an hour</li>
                    </ol>
                </InfoContainer>
                <InfoContainer>
                    <h3>Withdrawal Process</h3>
                    <ol>
                        <li>In the menu, click wallet</li>
                        <li>Click on withdraw money</li>
                        <li>Steps will be shown within the app</li>
                        <li>Fill all fields and submit request</li>
                        <li>Your request will be processed within an hour</li>
                    </ol>
                </InfoContainer>
                <InfoContainer>
                    <h3>Livestreams</h3>
                    <p>We currently support these genres:</p>
                    <ul>
                        <li>Sports</li>
                        <li>Racing</li>
                        <li>Tournament</li>
                        <li>E-sports</li>
                        <li>Live Casino Games</li>
                        <li>Virtual Sports</li>
                        <li>Gaming (Video Games, Esports)</li>
                        <li>Animal Fights</li>
                        <li>Live Bloggers</li>
                        <li>Others</li>
                    </ul>
                </InfoContainer>
                <InfoContainer>
                    <h3>Betting</h3>
                    <p>We currently support these betting types:</p>
                    <ul>
                        <li>Match Winner</li>
                        <li>Per Game</li>
                    </ul>
                    <p>
                        Here are the considerations when making a bet:
                    </p>
                    <ul>
                        <li>You can bet as many teams as you want</li>
                        <li>Per bet, you are required with a minimum of 100</li>
                        <li>You cannot bet when it is not opened yet</li>
                        <li>When the streamer closes the bet, you can't bet anymore</li>
                        <li>The streamer can cancel the bet, you will be refunded in full immediately</li>
                    </ul>
                </InfoContainer>
            </div>
        </Container>
    )
}

const InfoContainer = styled.div`
    background-color: rgba(20, 87, 255, 0.068);
    border: 1px solid lightblue;
    box-shadow: 0 0px 15px rgba(20, 87, 255, 0.4);
    &:hover{
        background-color: rgba(20, 87, 255, 0.168);
        box-shadow: 0 5px 15px rgba(20, 87, 255, 0.4);
    }
    padding: 1em;
    border-radius: 10px;
    width: 350px;

    h3{
        margin: 0;
        padding: 0;
    }

    a{
        color: lightblue;
    }
    li{
        margin-bottom: 1em;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: lighter;
    letter-spacing: 2px;

    h1{
        text-shadow: 0 0px 15px rgba(20, 87, 255, 0.688);
    }
    margin-bottom: 300px;

    .infos{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3em;
    }
`;

export default LearnMore