import React from 'react';
import styled from 'styled-components';
import logoTransparent from "../assets/logo-transparent.png"

const FooterContainer = styled.footer`
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: center;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLogo = styled.img`
  width: 100px;
  margin-bottom: 10px;
`;

const FooterText = styled.p`
  margin: 5px 0;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLogo src={logoTransparent} alt="AyLive Logo" />
        <FooterText>© AyLive 2024. All rights reserved.</FooterText>
        <FooterText>Join us for an unforgettable experience!</FooterText>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
