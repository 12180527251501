import React, { useEffect, useRef } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Fade from 'embla-carousel-fade'
import {
    NextButton,
    PrevButton,
    usePrevNextButtons
} from './EmblaCarouselArrowButtons'

import dota from "../assets/dota.jpg"
import nba from "../assets/nba.jpg"
import animal from "../assets/animal.jpg"
import casino from "../assets/casino.jpg"
import esports from "../assets/esports.jpg"
import fifa from "../assets/fifa.jpg"
import racing from "../assets/racing.jpg"

const images = [dota, nba, animal, casino, esports, fifa, racing]

const EmblaCarousel = (props) => {
    const { slides, options } = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [Fade()])

    const nextButtonRef = useRef(null);

    useEffect(() => {
        console.log('Setting up interval');
        const interval = setInterval(() => {
            console.log(emblaApi)
            if (emblaApi != null) {
                emblaApi.scrollNext()

            }
        }, 2000);

        return () => {
            console.log('Cleaning up interval');
            clearInterval(interval);
        };
    }, [emblaApi]); // 



    const {
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)

    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((index) => (
                        <div className="embla__slide" key={index}>
                            <img
                                className="embla__slide__img"
                                src={images[index]}
                                alt="Your alt text"
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className="embla__controls">
                <div className="embla__buttons">
                    <PrevButton onClick={onPrevButtonClick} />
                    <NextButton onClick={onNextButtonClick} ref={nextButtonRef} />
                </div>
            </div> */}
        </div>
    )
}

export default EmblaCarousel
